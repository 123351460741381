import React, { useContext, useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { postRequest } from "../util/request";

const UserSessionContext = React.createContext();

export function useUserSession() {
  return useContext(UserSessionContext);
}

export function UserSessionProvider({ children }) {
  const [sessionHash, setSessionHash] = useState();
  const [loading, setLoading] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [cookies, setCookie] = useCookies(["sessionHash"]);

  async function login(email) {
    setLoading(true);
    const { success, error, sessionHash } = (
      await postRequest(`${process.env.REACT_APP_API_URL}/userSession/login`, {
        email,
      })
    ).data;

    setLoading(false);

    if (success && sessionHash) {
      setSessionHash(sessionHash);
      setLoggedIn(true);
      setCookie("sessionHash", sessionHash, {
        domain: process.env.REACT_APP_USER_SESSION_COOKIE_DOMAIN,
        httpOnly: false,
        path: "/",
        expires: 0,
      });

      sessionStorage.setItem("sessionHash", sessionHash);

      return true;
    }

    setSessionHash(null);
    setLoggedIn(false);
    return error;
  }

  useEffect(() => {
    if (cookies.sessionHash) {
      setSessionHash(cookies.sessionHash);
      sessionStorage.setItem("sessionHash", cookies.sessionHash);
      setLoggedIn(true);
    }

    return () => {
      setLoggedIn(false);
      setSessionHash(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = {
    loading,
    sessionHash,
    login,
    loggedIn,
  };

  return (
    <UserSessionContext.Provider value={value}>
      {children}
    </UserSessionContext.Provider>
  );
}
