import axios from "axios";
import { auth } from "../firebase";

export const getRequest = (url) =>
  axios.get(url, {
    headers: { "X-Session-Hash": sessionStorage.getItem("sessionHash") },
  });

export const postRequest = (url, params) =>
  axios.post(url, params, {
    headers: { "X-Session-Hash": sessionStorage.getItem("sessionHash") },
  });

export const authorizedGetRequest = async (url) => {
  const token = await auth.currentUser.getIdToken();

  return axios.get(url, { headers: { authorization: `Bearer ${token}` } });
};

export const authorizedPostRequest = async (url, jsonBody) => {
  const token = await auth.currentUser.getIdToken();

  return axios.post(url, jsonBody, {
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  });
};
