import React from "react";
import { Link /* useLocation */ } from "react-router-dom";

export default function Navigation() {
  /* const location = useLocation(); */
  /* const { pathname: currentUrlKey } = location;
   */
  return (
    <div className="navigation">
      {/* <Link
        className={`hidden-mobile ${"/" === currentUrlKey ? "active" : ""}`}
        to="/"
      >
        Home
      </Link> */}
      {/* {stages &&
        stages.map((s) => (
          <Link
            key={`/stage/${s.url}`}
            to={`/stage/${s.url}`}
            className={`/stage/${s.url}` === currentUrlKey ? "active" : ""}
          >
            {s.stageName}
          </Link>
        ))} */}
      {/* <Link
        to="/schedule"
        key="/schedule"
        className={`hidden-mobile ${
          "/schedule" === currentUrlKey ? "active" : ""
        }`}
      >
        Schedule
      </Link> */}
      <Link
        to="/IS2023-schedule.pdf"
        target="_blank"
        title="Inflammation Summit 2023 - Schedule as PDF"
        alt="Inflammation Summit 2023 - Schedule as PDF"
        style={{ color: "#189CCC" }}
      >
        Zeitplan herunterladen
      </Link>
    </div>
  );
}
