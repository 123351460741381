import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Popover,
  Row,
  Space,
  Spin,
  Tabs,
  Upload,
} from "antd";
import { auth } from "../../firebase";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { authorizedPostRequest } from "../../util/request";
import AdminLiveStatistics from "./AdminLiveStatistics";
import AdminStatistics from "./AdminStatistics";

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [authToken, setAuthToken] = useState(null);
  const [activeTabKey, setActiveTabKey] = useState("dashboard");

  const [fileList, setFileList] = useState([]);

  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [oegkNr, setOegkNr] = useState(null);

  async function saveManualEmail() {
    setLoading(true);
    const {
      success,
      error,
      message: m,
    } = (
      await authorizedPostRequest(
        `${process.env.REACT_APP_API_URL}/admin/addManualEmail`,
        { firstName, lastName, email, oegkNr }
      )
    ).data;

    setLoading(false);
    if (success) {
      message.success(m);
    } else {
      message.error(error);
    }
  }

  useEffect(() => {
    (async () => {
      const token = await auth.currentUser.getIdToken();

      if (token) {
        setAuthToken(token);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dashboardContent = (
    <Row gutter={[24, 24]}>
      <Col xs={24}>
        <Row gutter={[24, 24]} style={{ marginTop: "12px" }}>
          <Col xs={24} lg={8}>
            <Space direction="vertical" style={{ width: "100%" }}>
              <h3 style={{ margin: 0 }}>
                Liste mit E-Mail Adressen hochladen{" "}
                <Popover
                  title="Format für CSV"
                  content={
                    <>
                      <b>OHNE Spaltenüberschriften</b>
                      <pre>Vorname;Nachname;E-Mail Adresse;ÖGKNr</pre>
                    </>
                  }
                >
                  <QuestionCircleOutlined />
                </Popover>
              </h3>
              <h5 style={{ color: "coral", margin: 0 }}>
                <b>Achtung: </b>es werden nur *neue* E-Mail Adressen eingefügt.
              </h5>
              <Upload
                method="POST"
                name="file"
                accept=".csv"
                action={`${process.env.REACT_APP_API_URL}/admin/uploadEmailAddressList`}
                headers={{ Authorization: `Bearer ${authToken}` }}
                multiple={false}
                fileList={fileList}
                onChange={(info) => {
                  setFileList([info.file]);
                  if (info && info.file.response) {
                    const { success, error, message: m } = info.file.response;

                    if (success) {
                      message.success(m);
                      setFileList([]);
                    } else {
                      message.error(error);
                    }
                  }
                }}
              >
                <Button>Auswählen ...</Button>
              </Upload>
            </Space>
          </Col>
          <Col xs={24} lg={8}>
            <Space direction="vertical" style={{ width: "100%" }}>
              <h3 style={{ margin: 0 }}>Person manuell hinzufügen</h3>
              <Form layout="vertical">
                <Form.Item label="Vorname">
                  <Input
                    placeholder="Vorname"
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                  />
                </Form.Item>
                <Form.Item label="Nachname">
                  <Input
                    placeholder="Nachname"
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                  />
                </Form.Item>
                <Form.Item label="E-Mail">
                  <Input
                    placeholder="E-Mail"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </Form.Item>
                <Form.Item label="ÖGK Nr.">
                  <Input
                    placeholder="ÖGK Nr."
                    value={oegkNr}
                    onChange={(e) => {
                      setOegkNr(e.target.value);
                    }}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    disabled={!firstName || !lastName || !email || !oegkNr}
                    onClick={() => {
                      saveManualEmail();
                    }}
                    loading={loading}
                  >
                    Speichern
                  </Button>
                </Form.Item>
              </Form>
            </Space>
          </Col>
        </Row>
      </Col>
    </Row>
  );

  return (
    <Card>
      <Spin spinning={loading} />
      <Tabs
        activeKey={activeTabKey}
        onChange={(k) => {
          setActiveTabKey(k);
        }}
        animated
        tabPosition="top"
        items={[
          { label: "Dashboard", key: "dashboard", children: dashboardContent },
          {
            label: "Live",
            key: "live",
            children: <AdminLiveStatistics visible={activeTabKey === "live"} />,
          },
          {
            label: "Alle User-Sessions",
            key: "all-user-sessions",
            children: (
              <AdminStatistics visible={activeTabKey === "all-user-sessions"} />
            ),
          },
        ]}
      />
    </Card>
  );
};

export default Dashboard;
