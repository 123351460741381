import { Alert, Button, Card, Input, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const navigate = useNavigate();

  const { currentUser, login } = useAuth();

  useEffect(() => {
    if (currentUser) navigate("/admin");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, navigate]);

  const handleLogin = async () => {
    setLoading(true);
    try {
      await login(username, password);
    } catch (err) {
      if (err.code && err.code === 400) {
        setError("Falsche Login-Daten.");
      } else {
        setError("Fehler beim Login.");
      }
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "48px 0",
      }}
    >
      <Card>
        <h1>Login</h1>
        <Space direction="vertical">
          {error && <Alert type="error" showIcon message={error} closable />}
          <Space>
            <Input
              placeholder="username"
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
              onKeyUp={(e) => {
                console.log("e.key", e.key);
                if (e.key === "Enter") {
                  handleLogin();
                }
              }}
            />
            <Input
              type="password"
              placeholder="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  handleLogin();
                }
              }}
            />
            <Button loading={loading} type="primary" onClick={handleLogin}>
              Login
            </Button>
          </Space>
        </Space>
      </Card>
    </div>
  );
};

export default Login;
