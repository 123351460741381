import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Alert, Button, Col, Row, Segmented, Spin, Steps } from "antd";
import moment from "moment-timezone";
import { FilePdfOutlined } from "@ant-design/icons";
import HTMLReactParser from "html-react-parser";
import { getRequest } from "../../util/request";

export default function Schedule({ activeStage }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [stages, setStages] = useState([]);
  const [dates, setDates] = useState([0]);
  const [selectedDate, setSelectedDate] = useState(null);

  const fetchRoomsAndSessions = async () => {
    setLoading(true);
    try {
      const { stages } = (
        await getRequest(`${process.env.REACT_APP_API_URL}/stagesAndSessions/`)
      ).data;

      if (stages) {
        if (activeStage) {
          setStages(stages.filter((r) => r.url === activeStage));
        } else {
          setStages(stages);
        }
      }
    } catch {
      setError("Error while fetching the schedule.");
    } finally {
      setLoading(false);
    }
  };

  const createTime = (date, time) => {
    const d = moment(date);
    const t = moment(time);

    return moment(
      `${d.format("YYYY-MM-DD")} ${t.format("HH:mm:ss")}`,
      "YYYY-MM-DD HH:mm:ss"
    );
  };

  useEffect(() => {
    fetchRoomsAndSessions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStage]);

  useEffect(() => {
    const allSessions = stages.reduce(
      (s, curr) => [...s, ...curr.sessions],
      []
    );
    const allDates = allSessions.reduce((d, curr) => {
      if (d.indexOf(curr.sessionDate) >= 0) {
        return d;
      }

      return [...d, curr.sessionDate];
    }, []);
    setDates(allDates);
    if (allDates.length > 0) {
      const currentDate = moment();
      const dateIndex = allDates.findIndex((ad) =>
        moment(ad).isSame(currentDate, "date")
      );
      setSelectedDate(
        moment(allDates[dateIndex >= 0 ? dateIndex : 0]).format(
          "ddd, DD.MM.YYYY"
        )
      );
    }
  }, [stages]);

  return (
    <div className="schedule">
      <Spin spinning={loading}>
        {error && (
          <Alert
            type="error"
            showIcon
            message={error}
            style={{ margin: "12px 0" }}
          />
        )}

        {!activeStage && (
          <Row>
            <Col xs={24}>
              Sie können den gesamten Zeitplan als PDF hier herunterladen:{" "}
              <Button
                type="link"
                href="/IS2023-schedule.pdf"
                target="_blank"
                title="Inflammation Summit 2023 - Schedule"
                alt="Inflammation Summit 2023 - Schedule"
                icon={<FilePdfOutlined />}
              >
                Zeitplan (0.2MB)
              </Button>
            </Col>
          </Row>
        )}

        {dates && (
          <Row style={{ margin: "24px 0" }}>
            <Col
              xs={24}
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <h2>Zeitplan</h2>
              <Segmented
                value={selectedDate}
                options={dates
                  .sort((d1, d2) => moment(d1).unix() - moment(d2).unix())
                  .map((d) => moment(d).format("ddd, DD.MM.YYYY"))}
                onChange={(val) => {
                  setSelectedDate(val);
                }}
              />
            </Col>
          </Row>
        )}

        <Row
          gutter={[24, 24]}
          style={{ marginTop: activeStage ? "48px" : "0" }}
        >
          {stages &&
            stages.map((s) => (
              <Col key={s.id} xs={24} md={activeStage ? 24 : 12}>
                <div className="schedule-card">
                  {s.sessions && s.sessions.length > 0 ? (
                    <Steps progressDot direction="vertical">
                      {s.sessions
                        .filter((s) =>
                          moment(s.sessionDate).isSame(
                            moment(selectedDate, "ddd, DD.MM.YYYY"),
                            "day"
                          )
                        )
                        .map((s) => (
                          <Steps.Step
                            key={s.id}
                            status="finish"
                            title={s.sessionTitle}
                            subTitle={`${createTime(
                              s.sessionDate,
                              s.sessionTimeFrom
                            )
                              .utcOffset(60)
                              .format("HH:mm")} - ${createTime(
                              s.sessionDate,
                              s.sessionTimeTo
                            )
                              .utcOffset(60)
                              .format("HH:mm")}`}
                            description={
                              s.sessionDescription ? (
                                <>
                                  {HTMLReactParser(s.sessionDescription)}
                                  {s.sessionDetailUrl && (
                                    <Button
                                      type="link"
                                      href={s.sessionDetailUrl}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      Details
                                    </Button>
                                  )}
                                </>
                              ) : null
                            }
                          />
                        ))}
                    </Steps>
                  ) : (
                    <small>No sessions on this day.</small>
                  )}
                </div>
              </Col>
            ))}
        </Row>
      </Spin>
    </div>
  );
}

Schedule.propTypes = {
  activeRoom: PropTypes.string,
};
