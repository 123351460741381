import React from "react";
import { Col, Row } from "antd";
import Navigation from "./Navigation";

export default function Header() {
  return (
    <Row className="header" gutter={0}>
      <Col
        xs={{ span: 22, offset: 1 }}
        md={{ span: 20, offset: 2 }}
        lg={{ span: 18, offset: 3 }}
        className="header-content"
      >
        <Row
          style={{
            width: "100%",
            justifyContent: "flex-end",
            flexDirection: "row",
            flexFlow: "nowrap",
          }}
        >
          <a
            href="/"
            title="Inflammation Summit Livestream"
            alt="Inflammation Summit Livestream"
            className="logo-link"
          >
            <img
              className="logo"
              src="/img/inflammation-summit-logo.png"
              alt="Inflammation Summit 2023"
              title="Inflammation Summit 2023"
            />
          </a>
          <div className="heading">
            <span className="h1">Inflammation Summit 2023</span>
            <span className="h2">31. März bis 1. April 2023</span>
          </div>
        </Row>
        <Navigation />
      </Col>
    </Row>
  );
}
