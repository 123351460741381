import React, { Component } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { message, Table, Typography } from "antd";
import { authorizedGetRequest } from "../../util/request";
import Dauer from "../../util/Dauer";

export default class AdminStatistics extends Component {
  state = {
    loading: false,
    currentSessions: [],
    parsedSessions: [],
  };

  constructor(props) {
    super(props);
    this.loadingInterval = null;
  }

  loadCurrentLiveSessions = async () => {
    this.setState({ loading: true });
    const { success, error, userSessions, parsedSessions } = (
      await authorizedGetRequest(
        `${process.env.REACT_APP_API_URL}/admin/allUserSessions`
      )
    ).data;

    if (success) {
      this.setState({
        loading: false,
        currentSessions: userSessions,
        parsedSessions,
      });
    } else {
      this.setState({ loading: false });

      message.error(error);
    }
  };

  componentDidMount = () => {
    const { visible } = this.props;

    if (visible) {
      // wenn eingeblendet, dann im 10sek takt aktualisieren
      this.loadingInterval = setInterval(
        this.loadCurrentLiveSessions,
        10 * 1000
      );

      // und noch laden, damit man nicht warten muss
      this.loadCurrentLiveSessions();
    }
  };

  componentDidUpdate = (prevProps) => {
    const { visible } = this.props;
    const { visible: wasVisible } = prevProps;

    if (visible && !wasVisible) {
      // wenn eingeblendet, dann im 10sek takt aktualisieren
      this.loadingInterval = setInterval(
        this.loadCurrentLiveSessions,
        10 * 1000
      );

      // und noch laden, damit man nicht warten muss
      this.loadCurrentLiveSessions();
    } else if (!visible && wasVisible) {
      // wenn ausgeblendet, interval stoppen
      clearInterval(this.loadingInterval);
    }
  };

  render() {
    const { loading, parsedSessions } = this.state;

    return (
      <>
        <Typography.Title level={4}>
          Alle aufgezeichneten Sessions {loading && <LoadingOutlined spin />}
        </Typography.Title>
        <Table
          rowKey="id"
          dataSource={parsedSessions}
          columns={[
            {
              title: "E-Mail Adresse",
              key: "email",
              render: (row) => row.user.emailAddress,
            },
            {
              title: "Name",
              key: "name",
              render: (row) => `${row.user.firstName} ${row.user.lastName}`,
            },
            {
              title: "ÖAK Nr",
              dataIndex: ["user", "oegkNr"],
            },
            {
              title: "Insgesamt zugesehen",
              key: "seconds",
              render: (row) =>
                row.activities && (
                  <pre style={{ margin: 0 }}>
                    <Dauer
                      seconds={row.activities.reduce(
                        (sum, curr) => sum + Number(curr.seconds),
                        0
                      )}
                    />
                  </pre>
                ),
            },
            /* {
              title: "Insgesamt",
              key: "seconds",
              render: (session) =>
                (session.UserActivity &&
                  session.UserActivity[0] &&
                  session.UserActivity[0].seconds && (
                    <pre style={{ margin: 0 }}>
                      <Dauer
                        seconds={session.UserActivity.reduce(
                          (sum, curr) => sum + Number(curr.seconds),
                          0
                        )}
                      />
                    </pre>
                  )) ||
                "-",
            }, */
          ]}
        />
      </>
    );
  }
}
