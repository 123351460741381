import React, { useEffect, useState } from "react";
import { Col, Row, Spin } from "antd";
import Stage from "../Stage/Stage";
import { getRequest } from "../../util/request";

const Home = () => {
  const [loading, setLoading] = useState(false);
  const [stages, setStages] = useState([]);

  const fetchStages = async () => {
    setLoading(true);
    try {
      const { stages } = (
        await getRequest(`${process.env.REACT_APP_API_URL}/stages/`)
      ).data;
      setStages(stages);
    } catch {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStages();
  }, []);

  return (
    <>
      <Row gutter={[0, 24]} style={{ marginTop: "24x" }}>
        <Col xs={24} md={24} lg={24}>
          <Spin spinning={loading}>
            {stages && stages.length > 0 && <Stage stageName={stages[0].url} />}
          </Spin>
        </Col>
      </Row>
    </>
  );
};

export default Home;
