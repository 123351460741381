function Dauer({ seconds }) {
  const createString = () => {
    const m = Math.floor((seconds / 60) % 60);
    const h = Math.floor(seconds / 60 / 60);
    const s = Math.floor(seconds % 60);

    return `${h.toString().padStart(2, "0")}:${m
      .toString()
      .padStart(2, "0")}:${s.toString().padStart(2, "0")}`;
  };

  return createString();
}

export default Dauer;
