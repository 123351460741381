import React from "react";
import { Col, Row } from "antd";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ConfigProvider } from "antd";
import deDE from "antd/locale/de_DE";
import "moment/locale/de";
import Dashboard from "./components/admin/Dashboard";
import Home from "./components/Home/Home";
import Login from "./components/Login";
import { AuthProvider } from "./contexts/AuthContext";
import RequireAuth from "./RequireAuth";
import Stage from "./components/Stage/Stage";
import Header from "./components/Header";
import Schedule from "./components/Schedule/Schedule";
import "./App.css";
import Footer from "./components/Footer";
import { UserSessionProvider } from "./contexts/UserSessionContext";

function App() {
  return (
    <ConfigProvider
      locale={deDE}
      theme={{
        token: {
          fontFamily: "Ubuntu",
          colorPrimary: "#1C3351",
          colorTextSecondary: "#189CCC",
        },
      }}
    >
      <BrowserRouter>
        <AuthProvider>
          <UserSessionProvider>
            <Header />
            <Row gutter={[0, 24]}>
              <Col
                xs={{ span: 22, offset: 1 }}
                md={{ span: 20, offset: 2 }}
                lg={{ span: 18, offset: 3 }}
              >
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/stage/:stageName" element={<Stage />} />
                  <Route path="/schedule" element={<Schedule />} />
                  <Route path="/login" element={<Login />} />
                  <Route
                    path="/admin"
                    element={
                      <RequireAuth>
                        <Dashboard />
                      </RequireAuth>
                    }
                  />
                </Routes>
              </Col>
            </Row>
            <Footer />
          </UserSessionProvider>
        </AuthProvider>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
