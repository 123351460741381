import React from "react";
import { Col, Row } from "antd";

export default function Footer() {
  return (
    <footer>
      <Row gutter={[24, 24]} style={{ width: "75%" }}>
        <Col xs={10} sm={8}>
          31 MÄRZ - 1 APRIL
          <br />
          Courtyard by Marriott Linz
          <br />
          Europaplatz 2, 4020 Linz
        </Col>
        <Col xs={6} sm={8} style={{ flexDirection: "column" }}>
          Inflammation Summit
        </Col>
        <Col xs={8} sm={8}>
          <div style={{ margin: "0px 8px" }}>
            <a
              href="https://domotion.live/datenschutz"
              target="_blank"
              rel="noreferrer"
            >
              Datenschutzerklärung
            </a>
          </div>
        </Col>
      </Row>
    </footer>
  );
}
