import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, Input, Row, Spin, Typography } from "antd";
import { useParams } from "react-router-dom";
import Schedule from "../Schedule/Schedule";
import { getRequest, postRequest } from "../../util/request";
import { useUserSession } from "../../contexts/UserSessionContext";
import useInterval from "../../util/useInterval";

export default function Stage(props) {
  const [loading, setLoading] = useState(false);
  const [stage, setStage] = useState(null);
  const [emailForLogin, setEmailForLogin] = useState("");
  const [loginError, setLoginError] = useState(null);

  // hit für tracking
  const hitRef = useRef(1);

  const { loading: loginLoading, login, loggedIn } = useUserSession();

  let { stageName } = useParams();

  if (!stageName && props.stageName) {
    stageName = props.stageName;
  }

  const handleLogin = async () => {
    const loginSuccess = await login(emailForLogin);

    if (loginSuccess !== true) {
      setLoginError(loginSuccess);
    } else {
      setLoginError(null);
    }
  };

  const fetchStage = async () => {
    setLoading(true);
    const stage = (
      await getRequest(`${process.env.REACT_APP_API_URL}/stage/${stageName}`)
    ).data;

    setLoading(false);
    setStage(stage);
  };

  const fetchCurrentStageActivity = async () => {
    try {
      const { success, error } = (
        await postRequest(
          `${process.env.REACT_APP_API_URL}/stageActivity/${stageName}`,
          { hit: hitRef.current }
        )
      ).data;

      if (success) {
        hitRef.current += 1;
      } else {
        console.log("error", error);
      }
    } catch {}
  };

  // interval für current activity
  useInterval(fetchCurrentStageActivity, 10 * 1000);

  useEffect(() => {
    if (loggedIn) {
      fetchStage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stageName, loggedIn]);

  useEffect(() => {
    hitRef.current = 1;
  }, [stageName]);

  return (
    <>
      <div className="stage">
        {!loggedIn && (
          <Row>
            <Col xs={24} md={{ span: 12, offset: 6 }}>
              <Card bodyStyle={{ textAlign: "center" }}>
                <Typography.Title level={4}>
                  Bitte geben Sie Ihre E-Mail Adresse an, um teilnehmen zu
                  können
                </Typography.Title>
                {loginError && (
                  <div style={{ margin: "12px 0" }}>
                    <Typography.Text type="warning">
                      {loginError}
                    </Typography.Text>
                  </div>
                )}
                <Form labelCol={{ xs: { span: 24 }, lg: 6 }}>
                  <Form.Item label="E-Mail Adresse">
                    <Input
                      type="email"
                      placeholder="E-Mail Adresse"
                      value={emailForLogin}
                      onChange={(e) => {
                        setEmailForLogin(e.target.value);
                      }}
                    />
                  </Form.Item>
                </Form>
                <div style={{ textAlign: "center" }}>
                  <Button
                    type="primary"
                    disabled={!emailForLogin}
                    loading={loginLoading}
                    onClick={handleLogin}
                  >
                    Teilnehmen
                  </Button>
                </div>
              </Card>
            </Col>
          </Row>
        )}
        {loggedIn && (
          <Card>
            <Spin spinning={loading}>
              <Row gutter={[24, 24]}>
                <Col xs={24} lg={18}>
                  {stage && (
                    <Card bodyStyle={{ padding: 0 }}>
                      <div
                        style={{
                          padding: "56.25% 0 0 0",
                          position: "relative",
                        }}
                      >
                        <iframe
                          title="Stream"
                          src={`https://vimeo.com/event/${stage.vimeoEventId}/embed`}
                          frameBorder="0"
                          allow="autoplay; fullscreen; picture-in-picture"
                          allowFullScreen
                          style={{
                            borderRadius: "12px",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                          }}
                        />
                      </div>
                    </Card>
                  )}
                </Col>
                <Col xs={24} lg={6}>
                  <Card bodyStyle={{ padding: 0 }}>
                    {stage && (
                      <iframe
                        src={`https://app.sli.do/event/${stage.slidoEventId}`}
                        height="100%"
                        width="100%"
                        frameBorder="0"
                        style={{ minHeight: "560px", borderRadius: "12px" }}
                        title="Slido"
                      />
                    )}
                  </Card>
                </Col>
              </Row>
              <Schedule activeStage={stage ? stage.url : null} />
            </Spin>
          </Card>
        )}
      </div>
    </>
  );
}
