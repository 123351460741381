import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "./contexts/AuthContext";

function RequireAuth({ children }) {
  let { currentUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!currentUser) navigate("/login");
  }, [currentUser, navigate, location]);

  return children;
}

export default RequireAuth;
